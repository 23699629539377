@charset "UTF-8";
.btn-primary {
  --cui-btn-hover-bg: #d9a420;
  --cui-btn-hover-border-color: #d9a420;
}

.btn {
  --cui-btn-hover-border-color: #d9a420;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  position: relative;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  overflow-x: hidden;
  background-color: #000000;
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/bgbodyimg1.gif);
  background-position: center;
  background-size: 80px;
  background-repeat: repeat;
  z-index: -2;
  filter: brightness(0.15) hue-rotate(175deg);
}

.topInformation {
  display: flex;
  flex-wrap: wrap;
}

.text-reveal {
  position: relative;
  /* overflow: hidden; */
  font-weight: 200;
  text-transform: capitalize;
  text-align: center;
  margin: 0 auto;
  /* width: 100%; */
  /* height: 100%; */
  /* text-align: left; */
  /* margin-block: auto; */
  /* margin-inline: 30px; */
  max-width: 702px;
}

span {
  display: inline-block;
}

.text-reveal h2 {
  font-size: 3rem;
  letter-spacing: 0.01em;
}

.buttonGroup .btn1,
.buttonGroup .btn2,
.buttonGroup .btn3 {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 40px;
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 0.8em;
  color: rgb(121, 103, 3);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition-duration: 3s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.144);
  background-size: 200% 200%;
}
.buttonGroup .btn1:hover,
.buttonGroup .btn2:hover,
.buttonGroup .btn3:hover {
  transform: scale(0.95) !important;
  transition-duration: 3s;
  animation: animatedgradient 5s ease infinite;
  background-position: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bgBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.flex {
  display: flex;
  justify-content: center;
}

.col-6 {
  flex-basis: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex-basis: 0 0 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  line-height: 1;
  letter-spacing: 0.01em;
}

span {
  font-family: "PT Sans";
  letter-spacing: 0.1em;
  font-size: 16px;
}

a {
  font-family: "PT Sans";
  letter-spacing: 0.1em;
}

button {
  font-family: "PT Sans";
  letter-spacing: 0.1em;
}

p {
  font-family: "PT Sans";
  letter-spacing: 0.1em;
}

.btnMU,
.btn-btnMU {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;
  border: 2px solid #fab105;
}
.btnMU span,
.btn-btnMU span {
  display: block;
  padding: 0px 12px;
}

.btn-btnMU:nth-child(1)::after {
  animation: unset;
}

.btn-btnMU:nth-child(2)::after {
  animation: unset;
}

.btn-btnMU:nth-child(3)::after {
  animation: unset;
}

.btn-btnMU:nth-child(4)::after {
  animation: unset;
}
@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.spincoin {
  height: 25px;
}

.gifbox {
  max-width: 330px;
  margin: auto;
}

a {
  color: #83c8ff;
  outline: none;
}
a:hover {
  color: #ffbebe;
}

#root {
  min-height: 100vh;
}

main {
  margin: 0 auto;
}
main section:nth-last-child(1) {
  margin-bottom: 0px;
}

header {
  padding: 10px 0;
  top: 0px;
  left: 0;
  width: 100%;
  background-image: linear-gradient(#020315, rgba(2, 3, 21, 0) 100%);
  z-index: 2;
}
header .header {
  padding: 0px 0px;
  min-height: auto;
  background-color: transparent;
  border: none;
  position: relative;
}
header .header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #020314;
  filter: blur(30px);
}
header .header-brand img {
  width: 100%;
  max-width: 200px;
}
header .header-nav a {
  padding: 0px 10px;
  text-transform: uppercase;
  text-decoration: none;
}
header .header-nav a.active {
  color: #ffbebe;
}
header .bgBody {
  position: absolute;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  z-index: 0;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  border: none !important;
}

.MuiFormControl-root .MuiFormLabel-root,
.MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}
.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused fieldset,
.MuiFormControl-root .MuiOutlinedInput-root:hover fieldset {
  border-color: white;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border: none;
}

.MuiInputBase-root fieldset {
  border: transparent;
}
.MuiInputBase-root input {
  color: #fff !important;
}
.MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #fff !important;
}
.MuiInputBase-root .MuiInputBase-input {
  color: #fff !important;
  border: 1px solid #fdc06c;
  border-radius: 6px;
}
.MuiInputBase-root .MuiSvgIcon-root {
  color: #fff;
}

.backsidebtn {
  background: #652fa3;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
}

/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: #283542;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #fdc06c;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* form style */
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
  margin: 0 auto;
}

/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

/* svg -- size */
svg {
  width: 17px;
  display: block;
}

/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(246, 178, 51);
  opacity: 0;
  visibility: hidden;
}

/* styling of input */
.input {
  color: var(--input-text-color);
  width: 100%;
  margin-inline: min(2em, var(--inline-padding-of-input) + var(--gap));
  background: none;
  border: none;
}

.input:focus {
  outline: none;
}

.input::-moz-placeholder {
  color: var(--text-color);
}

.input::placeholder {
  color: var(--text-color);
}

/* input background change in focus */
.input:focus ~ .fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
.input:focus ~ .search {
  fill: var(--active-color);
}

/* showing close button when typing */
.input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.incometable button {
  color: #fff;
}
.incometable td {
  color: #fff !important;
}
.incometable th {
  color: #fff !important;
}

.incometittle {
  background: #652fa3;
  font-family: "Poppins", sans-serif !important;
}
.incometittle tr th {
  color: #fff !important;
}

footer {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
}
footer .copyright {
  color: #ffffff;
}
footer .footer {
  background-color: transparent;
  border: none;
  padding: 60px 0px 15px;
  position: relative;
  overflow: hidden;
}
footer .social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .social-icons a {
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #83c8ff;
  border: 1px solid #83c8ff;
  border-radius: 3px;
}
footer .social-icons a:hover {
  border-color: #ffbebe;
  color: #ffbebe;
}

.footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #404040;
  position: relative;
}
.footer_bottom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #020314;
  filter: blur(30px);
}

.copyright_right p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #aaa6b9;
  font-weight: 500;
  text-transform: uppercase;
}
.copyright_right a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.footer_social ul {
  margin-bottom: 0px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social li {
  margin-right: 10px;
  padding-right: 20px;
  position: relative;
}
.footer_social li::before {
  position: absolute;
  content: "||";
  right: 0;
  top: 50%;
  transform: translatey(-50%);
}
.footer_social li:last-child::before {
  content: none;
}
.footer_social a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  text-align: center;
}
.footer_social svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

.bannerarea {
  margin: 0 auto 100px;
  max-width: 100%;
  width: auto;
}
.bannerarea img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 0px 206px #000000);
}

.nftMain {
  padding: 100px;
  background: #000;
}

.nftmainimg img {
  max-height: 700px;
}

.listViewBx {
  border-radius: 6px;
  position: relative;
  margin: 20px auto;
}
.listViewBx .refbox {
  margin-top: 18px;
}
.listViewBx .inrInfoBx {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #cba857;
  padding: 10px;
  border-radius: 10px;
  background: #000;
}
.listViewBx .inrInfoBx .imginrBx {
  position: relative;
  flex: 0 0 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  max-height: 100px;
}
.listViewBx .inrInfoBx p {
  font-size: 19px;
  font-family: "PT Sans", sans-serif;
}
.listViewBx .inrInfoBx strong {
  font-size: 31px;
  display: block;
  font-family: "PT Sans";
}
.listViewBx .inrInfoBx img {
  margin: 0 auto 0px;
  display: block;
  position: relative;
  animation: zoominout 3s alternate infinite;
  max-width: 100%;
  height: 100%;
  width: auto;
}
@keyframes zoominout {
  0%, 100% {
    transform: scale(1) rotate(-5deg);
  }
  50% {
    transform: scale(0.95) rotate(0);
  }
}

.coinbox {
  max-width: 450px;
}

.lazyload-wrapper video {
  transform: scale(1);
  mix-blend-mode: lighten;
}

.lazyload-wrapper svg {
  mix-blend-mode: difference;
}

.nftBalance .balanceViewBx .balancewrap {
  margin-bottom: 30px;
  border-radius: 0px;
  box-shadow: 0px 0px 100px -80px #ffffff;
  border-radius: 6px;
  height: 100%;
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  border: 1px solid #020004;
  padding: 20p;
  background-size: contain;
  background-color: #000;
}
.nftBalance .balanceViewBx .balancewrap::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  border-radius: 6px;
  background-size: 300% 300%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.nftBalance .balanceViewBx .balancelist:first-child {
  border-radius: 6px 6px 0 0px;
}
.nftBalance .balanceViewBx .balancelist:last-child {
  border-radius: 0px 0px 6px 6px;
}
.nftBalance .balanceViewBx .balancelist {
  position: relative;
  padding: 10px 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  background: rgba(58, 47, 47, 0.23);
}
.nftBalance .balanceViewBx .balancelist span {
  font-size: 16px;
}
.nftBalance .balanceViewBx .balancelist:nth-last-child(1) {
  margin-bottom: 0px;
}
.nftBalance .balanceViewBx .balancelist svg {
  margin-left: 10px;
  width: 22px;
  height: auto;
  display: inline-block;
  cursor: pointer;
}
.nftBalance .balanceViewBx .gifbox {
  max-width: 120px;
  margin: 12px auto;
  z-index: 999;
  position: relative;
  background: rgba(58, 47, 47, 0.46);
  border-radius: 50%;
}
.nftBalance .balanceViewBx .gifbox img {
  width: 100%;
  height: auto;
}

.newstake .leftinfo {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 6px;
}

.nftMain .nftheadline {
  font-size: 42px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
}
.nftMain .leftinfo {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
}
.nftMain .leftinfo .highlight {
  padding-left: 8px;
  padding-right: 6px;
  border-radius: 4px;
  font-size: 22px;
  color: #33efb0;
  font-weight: 700;
}
.nftMain .labeltag {
  font-size: 16px;
  opacity: 0.9;
}
.nftMain .labelamount {
  font-size: 16px;
  opacity: 1;
  color: #fff;
  font-weight: 700;
}
.nftMain .boxbtn1 {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.nftMain .rightinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.nftMain .stakebtnarea {
  position: relative;
  text-align: center;
  margin-bottom: 15px;
}
.nftMain .stakebtnarea span {
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  color: #fff;
  display: block;
}
.nftMain a {
  color: #e3e3e3;
  text-decoration: none;
}
.nftMain .stakebtn {
  padding: 5px 16px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  width: 62%;
  margin-bottom: 25px;
  border: 1px solid #5411a1;
}
.nftMain .popular_gaming_inner .col-3 {
  flex-basis: 0 0 25%;
  max-width: 25%;
}
.nftMain .popular_gaming_inner .swiper-button-prev {
  background-color: #873787;
  cursor: pointer;
  height: 50px;
  left: 0;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 9999999;
}
.nftMain .popular_gaming_inner .swiper-button-prev::before {
  align-items: center;
  content: "↞";
  display: flex;
  font-size: 36px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.nftMain .popular_gaming_inner .swiper-button-next {
  background-color: #873787;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 9999999;
}
.nftMain .popular_gaming_inner .swiper-button-next::before {
  align-items: center;
  content: "↠";
  display: flex;
  font-size: 36px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.nftMain .popular_gaming_inner .notearea {
  margin: 0 auto;
  padding: 12px;
  text-align: justify;
}
.nftMain .popular_gaming_inner .nftsvideo {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.nftMain .popular_gaming_inner .nftsvideo :disabled {
  background: rgba(101, 47, 163, 0.5);
  box-shadow: none;
}
.nftMain .popular_gaming_inner .nftsvideo :disabled:hover {
  background: rgba(101, 47, 163, 0.5);
  box-shadow: none;
}
.nftMain .popular_gaming_inner .nftsvideo .itembox {
  flex: 0 0 50%;
}
.nftMain .popular_gaming_inner .nftsvideo1 {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  background: rgba(38, 38, 40, 0.7);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 40px 25px;
}
.nftMain .popular_gaming_inner .nftsvideo1 :disabled {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.nftMain .popular_gaming_inner .nftsvideo1 :disabled:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.nftMain .popular_gaming_inner .nftsvideo1 .itembox {
  flex: 0 0 50%;
}
.nftMain .popular_gaming_inner .leftinfo .labeltag1 {
  font-size: 20px;
  position: relative;
}
.nftMain .popular_gaming_inner .leftinfo .labeltag1::before {
  content: "";
  position: absolute;
  background: url(../images/shape-bg.png);
  height: 100%;
  width: 212px;
  left: -70px;
  background-repeat: no-repeat;
}
.nftMain .popular_gaming_inner .leftinfo .labeltag1::after {
  content: "";
  position: absolute;
  background: url(../images/clock.svg);
  height: 20px;
  width: 100%;
  left: -30px;
  background-repeat: no-repeat;
  top: 5px;
}
.nftMain .popular_gaming_inner .cardheading {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.nftMain .popular_gaming_inner .nftboxtitle {
  align-items: center;
  background-color: #686868;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  left: -60px;
  padding: 7px 5px;
  position: absolute;
  text-align: center;
  top: 40px;
  transform: rotate(-55deg);
  width: 200px;
  z-index: 1;
}
.nftMain .popular_gaming_inner .swiper-slide {
  overflow: hidden;
}

.stakebtn {
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  border: 1px solid #5411a1;
  background: transparent;
}

.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);
}
.connectWalletToggle .modal-dialog {
  max-width: 450px;
}
.connectWalletToggle .modal-content {
  color: #ffffff;
  background-color: #0a0a0a;
  box-shadow: 0px 0px 70px -40px #666666;
  padding: 0px 20px;
}
.connectWalletToggle .modal-content::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.connectWalletToggle .modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px 10px;
}
.connectWalletToggle .modal-content .modal-body {
  padding: 10px;
}
.connectWalletToggle .modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
.connectWalletToggle .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: "PT Sans";
  color: yellow;
  text-shadow: 0.3px 0.3px 0.3px #111;
  letter-spacing: 0.11em;
  color: #3c2900;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656, 0 0 11px #ffe656;
}
.connectWalletToggle .modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}
.connectWalletToggle .popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.connectWalletToggle .popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 15px);
  max-width: calc(33.33% - 15px);
  margin: 0px 7px 20px;
  display: block;
  position: relative;
}
.connectWalletToggle .popupbtngrp .btngrp::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: 0;
  background-size: 300% 300%;
}
.connectWalletToggle .popupbtngrp button,
.connectWalletToggle .popupbtngrp a {
  background-color: #000000;
  color: blanchedalmond;
  font-size: 16px;
  padding: 20px 8px 20px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1.1;
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  font-family: "PT Sans";
  border: transparent;
}
.connectWalletToggle .popupbtngrp button:hover,
.connectWalletToggle .popupbtngrp a:hover {
  border-color: #523664;
}
.connectWalletToggle .popupbtngrp button img,
.connectWalletToggle .popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.169);
  -o-object-fit: contain;
     object-fit: contain;
}
.connectWalletToggle .popupbtngrp button span,
.connectWalletToggle .popupbtngrp a span {
  display: block;
}

.informationMU .benefitcontent {
  padding: 20px;
}
.informationMU .infotitle {
  margin-bottom: 30px;
}
.informationMU .infotitle h2 {
  font-size: 48px;
}
.informationMU .infoimage img {
  max-height: 500px;
}
.informationMU .infotxt h3 {
  font-size: 42px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 600;
  font-family: "Poppins";
}
.informationMU .infotxt p {
  letter-spacing: 1px;
  color: #c9c9c9;
  text-align: justify;
  font-size: 15px;
}
.informationMU .infotxt p span {
  color: #ffffff;
  font-weight: 600;
  font-size: 19px;
}

.faqheading {
  font-size: 42px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 600;
  font-family: "Poppins";
}

.account {
  display: flex;
  justify-content: center;
}

.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  -o-object-fit: cover;
     object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.1;
  mix-blend-mode: difference;
}
.bgVideoBody,
.bgVideoBody video {
  -o-object-fit: cover;
  object-fit: cover;
}

.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
  font-family: "PT Sans";
}
.countDownMu b {
  display: flex;
  text-align: center;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  margin: 0px 10px;
  border-radius: 6px;
  position: relative;
}
.countDownMu b span {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.countDownMu p {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 15px auto 0px;
  text-align: center;
  font-size: 16px;
  font-family: "PT Sans" sans-serif;
}

.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.newsbox {
  position: relative;
  border: 1px solid #43394d;
  margin: 0 20px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(23, 9, 45, 0.4588235294);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 16px;
  width: calc(100% - 20px);
  box-shadow: inset 0 0 35px 3px rgb(80, 50, 130);
}

.roundbg {
  position: relative;
  height: 90px;
  width: 120px;
  margin: 0 auto;
}
.roundbg img {
  position: relative;
  filter: drop-shadow(2px 4px 6px black);
}

.label1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(58, 3, 158, 0.3);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 5px #000;
}

.infolabel {
  margin-top: 20px;
}
.infolabel .btnMU,
.infolabel .btn-btnMU {
  font-size: 16px;
}

.news h3,
.nftBalance h3 {
  font-size: 42px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
}

.newssec .alice-carousel__dots-item:not(.__custom) {
  background-color: #ffffff;
  border-radius: 2px;
  height: 15px;
  transform: skew(-10deg);
  width: 15px;
  opacity: 0.1;
}
.newssec .alice-carousel__dots-item:not(.__custom):hover,
.newssec .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #711dff;
  opacity: 1;
}
.newssec .alice-carousel__prev-btn-item,
.newssec .alice-carousel__next-btn-item {
  display: inline-flex;
  cursor: pointer;
  margin: 0;
  color: #fff;
  font-weight: bolder;
}
.newssec .alice-carousel__prev-btn-item span,
.newssec .alice-carousel__next-btn-item span {
  height: 40px;
  width: 40px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  display: flex;
  font-size: 16px;
  background: #23143b;
}
.newssec .alice-carousel__prev-btn,
.newssec .alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 5px;
  position: absolute;
  right: 0;
  top: -71px;
}
.newssec .alice-carousel__prev-btn {
  text-align: right;
  right: 50px;
}
.newssec .alice-carousel__prev-btn-item:hover,
.newssec .alice-carousel__next-btn-item:hover {
  color: unset;
}
@media (max-width: 576px) {
  .newssec .alice-carousel__prev-btn,
  .newssec .alice-carousel__next-btn {
    display: none;
  }
}

.coin {
  background-color: #000;
  border-radius: 6px;
  position: relative;
  padding: 15%;
  margin: 0 30%;
}
.coin::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.coin .inbx {
  position: absolute;
  margin-top: 0;
}

.infotxt h4 {
  text-align: center;
  margin-bottom: 20px;
}

.inputvalue .value {
  margin: 5px;
  padding: 7px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background: #fff;
  border: 3px solid #fff;
  border-radius: 7px;
  width: 70%;
}
.inputvalue .value .value:hover {
  border: 3px solid #fff;
}

.MuiTabs-scrollButtonsDesktop {
  display: flex !important;
}

.MuiTabs-flexContainer {
  display: flex;
}

.MuiTabs-indicator {
  display: none;
}

.tabbar {
  margin-block: 32px;
}

body .MuiBox-root {
  background-color: transparent;
}
body .MuiBox-root .MuiInputBase-root {
  margin: 0;
}

.MuiTabs-flexContainer {
  justify-content: space-between;
}

.MuiTabs-scroller .MuiTabs-indicator {
  display: none;
  background-color: transparent;
}

.MuiTabs-scroller .MuiTabs-flexContainer button {
  color: #fff;
  border: 1px solid #fdc06c;
  margin: 0 10px;
  border-radius: 6px;
  font-size: 16px;
  min-width: 90px;
  background-color: transparent;
}
.MuiTabs-scroller .MuiTabs-flexContainer button.Mui-selected {
  background: transparent;
  font-weight: 600;
  background-size: 200%;
  border: 1px solid #fdc06c;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
}

.value {
  flex: 0 0 50%;
  border: none;
  outline: none;
  padding: 8px;
  background: transparent;
  border: 1px solid #b68f3a;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
}

.inputwrap {
  position: relative;
}

.labelspan1 {
  font-size: 26px !important;
  color: rgb(224, 199, 62) !important;
}

.Zebheading {
  font-size: 40px;
  letter-spacing: 0.05em;
}

.maxbtn {
  position: absolute;
  right: 5px;
  top: 50%;
  padding: 5px;
  transform: translate(0%, -50%);
  border-radius: 6px;
  color: rgb(121, 103, 3) !important;
  font-weight: bolder !important;
}

.copy-button {
  border: #fff;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputbar {
  font-size: 19px;
  margin: 0 auto;
  border-radius: 11px;
  margin-top: -7px;
  padding: 5px;
  background: #54575a;
}

.form-select {
  width: 70%;
  display: inline;
  margin: 10px;
  filter: hue-rotate(45deg);
}
.form-select option {
  background: #000 !important;
  color: #fff;
}

.dilog {
  border: 1px solid #e8e1e1;
  border-radius: 6px;
  margin-left: 25%;
  padding: 8px;
  width: 50%;
}
.dilog h3 {
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

.form-select2 {
  width: 70%;
  display: inline;
  margin: 10px;
  filter: hue-rotate(45deg);
  margin-left: 25%;
  width: 50%;
  padding: 9px;
  border-radius: 6px;
}
.form-select2 option {
  padding: 0 !important;
  background: #000 !important;
  color: #fff !important;
}

.inputvalue2 {
  margin: 3px;
}

.stakebtn2 {
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  margin-left: 26%;
  width: 48%;
}

.stakebtn2::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  background-size: 300% 300%;
}

.bgGIF {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -3;
  top: 0;
  left: 0;
  max-width: 100%;
  background-size: cover;
}

.logo {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-bottom: 30px;
  text-align: left;
}

.logo b {
  font-size: 50px;
  color: #fee;
}

.inputbox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.boxbtn {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  width: 190px;
  font-size: 19px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  border: none;
  background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
  color: #fff;
  border-radius: 5px;
  font-family: "PT Sans";
  color: rgb(121, 103, 3);
  font-weight: 600;
  background-size: 200%;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}
.boxbtn2 {
  align-items: center;
  background-image: url(../images/btnbgborder.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 48px;
  min-width: 167px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;
}
.boxbtn2:hover {
  color: #f8aa45;
}

.wnbtn {
  background-color: #bc4242;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 4px;
}

.mobilebtn {
  display: none;
}

.wnbtn2 {
  background-color: #0763af;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 10px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
}

.wnbtn2::marker {
  color: white;
}

.card {
  width: 100% !important;
  height: 100%;
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 18px;
  /* text-lg */
  text-align: center;
}

@media (min-width: 768px) {
  /* md: breakpoint */
  .title {
    font-size: 20px;
    /* text-xl */
  }
}
.description {
  color: #e2e8f0;
  /* text-gray-200 */
  text-align: center;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #fff;
  border: 2px solid #5411a1;
  font-size: 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  font-family: "PT Sans", sans-serif;
  box-shadow: inset 0 0 30px #5411a1;
}

.fqbox {
  padding: 22px;
}

.test {
  border-radius: 8px;
  border: 1px solid #5411a1;
  margin: 8px;
  flex: 0 0 calc(33.33% - 20px);
  background-color: #000;
}

.answer {
  padding-top: 8px;
  font-size: 15px;
  color: rgba(181, 191, 205, 0.6823529412);
}

.boxborder1 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder2 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

.boxborder3 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  width: 300px;
  height: 100px;
  padding: 50px;
  border: 3px solid #652fa3;
  margin: 10px;
  animation: mymove 5s infinite;
}

@keyframes mymove {
  50% {
    border-radius: 50px;
  }
}
.hello .MuiTabs-flexContainer {
  justify-content: center;
}
@media (max-width: 420px) {
  .hello .MuiTabs-flexContainer {
    justify-content: unset;
  }
}
.hello .MuiTabs-indicator {
  display: none !important;
}

.box {
  margin: 0 auto;
  display: flex;
  gap: 9px;
  justify-content: center;
  margin-top: 20px;
  padding: 0 5px;
  flex-direction: column;
  align-items: center;
}
.box.swapChange {
  flex-direction: column-reverse;
}

.boxbutton {
  background-color: red;
}
.boxbutton h4 {
  font-size: 10px;
}
.boxbutton .boxbutton:hover {
  background: red;
}

.boxttn {
  color: #fff;
}

.statushighlight {
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  background: #5411a1;
}

.myPagination {
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #000;
}
.myPagination button {
  color: #fff;
}

.faq .accordion-item {
  border: var(--cui-accordion-border-width) dashed var(--cui-accordion-border-color);
}
.faq .faq {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq .title {
  font-size: 40px;
  margin: 1rem auto;
  text-align: start;
  font-weight: 700;
  position: relative;
  text-transform: capitalize;
  color: #fff;
}
.faq .title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 2px;
  border-radius: 3px;
  border-bottom: 4px solid #ff7066;
  width: 50px;
}
.faq ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}
.faq li {
  position: relative;
  list-style: none;
  font-size: 20px;
  padding-block: 15px;
  padding-inline: 15px;
  color: #a09faf;
  cursor: pointer;
}
.faq li::before {
  content: "";
  position: absolute;
  left: 50%;
  background: linear-gradient(180deg, #ff7066 0%, #ff986b 100%);
  transition: all 0.5s;
  height: 35px;
  border-radius: 50%;
  z-index: -1;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  opacity: 0.5;
  cursor: pointer;
}
.faq li.react-tabs__tab--selected {
  color: #fff;
  border: none;
  --webkit-tap-highlight-color: none;
  outline: none;
}
.faq li.react-tabs__tab--selected::before {
  opacity: 1;
}
.faq .content-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 20px;
}
.faq .content-area {
  flex: 1 1 50%;
}
.faq .image-area {
  flex: 1 1 50%;
}
.faq .image-area img {
  width: auto;
  max-width: 100%;
}
.faq .accordion {
  --cui-accordion-bg: tramsparent;
  --cui-accordion-btn-color: #fff;
  --cui-accordion-color: #fff;
  --cui-accordion-btn-icon: none;
  --cui-accordion-btn-active-icon: none;
  --cui-accordion-btn-icon-transform: rotate(90deg);
  --cui-border-color: #373d41;
  --cui-accordion-body-padding-y: unset;
  --cui-accordion-btn-padding-x: 0rem;
}
.faq .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
  border-color: #fff;
  background: transparent;
  transition: all 0.3s ease-in-out;
}
.faq .accordion-button:not(.collapsed) {
  color: #fff;
  background: transparent;
  box-shadow: none;
}
.faq .accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.faq button.accordion-button {
  font-size: 22px;
}
.faq button.accordion-button:hover::after {
  background: #ff7066;
}
.faq button.accordion-button:hover::before {
  background: #ff7066;
}
.faq .accordion-button::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background: #603bbc;
  color: #fff;
  top: 50%;
  left: calc(100% - 30px);
  transform: translate(-50%, -50%);
}
.faq .accordion-button::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  background: #8950c9;
  left: calc(100% - 30px);
  color: #fff;
  top: 50%;
  transform: translate(-50%, -50%);
}
.faq .accordion-button:not(.collapsed)::after {
  width: 0;
}
.faq .accordion-body {
  padding-bottom: 16px;
}
@media (max-width: 1200px) {
  .faq .content-wrap {
    display: flex;
    flex-direction: column;
  }
  .faq .content-area {
    width: 100%;
  }
  .faq .image-area {
    width: auto;
    margin: 30px auto;
  }
}
@media (max-width: 768px) {
  .faq .image-area {
    margin: 50px auto;
  }
}
@media (max-width: 420px) {
  .faq button.accordion-button {
    font-size: 18px;
  }
}

.loader {
  height: 30px;
  display: inline-block;
}

.loader > div {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  box-shadow: 0 0 10px orange;
  background: #ffecba;
  float: left;
  margin: 5px;
  transform: scale(2);
}

.loader > div:nth-child(1) {
  animation: anm-BL-53-move1 1s infinite linear;
}

.loader > div:nth-child(2) {
  animation: anm-BL-53-move2 1s infinite linear;
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation: anm-BL-53-move3 1s infinite linear;
  animation-delay: 0.3s;
}

.loader > div:nth-child(4) {
  animation: anm-BL-53-move4 1s infinite linear;
  animation-delay: 0.4s;
}

.loader > div:nth-child(5) {
  animation: anm-BL-53-move5 1s infinite linear;
  animation-delay: 0.5s;
}

@keyframes anm-BL-53-move1 {
  50% {
    background: #ffbb00;
    transform: scale(1);
  }
}
@keyframes anm-BL-53-move2 {
  50% {
    background: #ffbb00;
    transform: scale(1);
  }
}
@keyframes anm-BL-53-move3 {
  50% {
    background: #ffbb00;
    transform: scale(1);
  }
}
@keyframes anm-BL-53-move4 {
  50% {
    background: #ffbb00;
    transform: scale(1);
  }
}
@keyframes anm-BL-53-move5 {
  50% {
    background: #ffbb00;
    transform: scale(1);
  }
}
.swapcontainer {
  transition: all 0.2s;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  box-shadow: inset 0px 0px 35px -15px #FFFFFF;
  overflow: hidden;
  padding-block: 35px;
}
.swapcontainer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 48px;
  background-repeat: no-repeat;
  background: url(../images/topp.webp);
  top: 0;
  background-repeat: no-repeat;
}
.swapcontainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 48px;
  background-repeat: no-repeat;
  background: url(../images/topp.webp);
  transform: scaleX(-1) scaleY(-1);
  bottom: 0;
  background-repeat: no-repeat;
}

.swapheader {
  position: relative;
  font-size: 28px;
  margin-top: -22px;
}

.texttittle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box1 {
  width: 100%;
  padding: 0 20px;
  border-radius: 20px;
}
.box1 h1 {
  font-size: 20px;
  text-align: center;
  margin-block: 6px;
  letter-spacing: 0.1em;
}
.box1 span {
  margin-left: 10px;
  text-transform: capitalize;
  margin-block: 4px;
}
.box1 img {
  mix-blend-mode: exclusion;
}

.box2 {
  width: 100%;
  padding: 0 20px;
  border-radius: 20px;
}
.box2 h1 {
  font-size: 20px;
  text-align: center;
  margin-block: 6px;
  letter-spacing: 0.1em;
}
.box2 span {
  margin-left: 10px;
  text-transform: capitalize;
  margin-block: 4px;
}

.icontext {
  display: flex;
  gap: 10px;
}

.iconimg2 {
  display: flex;
  gap: 10px;
}

.swapcontainer .btnswap a {
  align-items: center;
  background-image: url(../images/btnbgborder.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 48px;
  min-width: 167px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;
}
.swapcontainer .btnswap a:hover {
  color: #f8aa45;
}

.btnswap {
  text-align: center;
}

.banner {
  display: block;
}

.bannerimg {
  max-width: 702px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .banner {
    display: block;
    margin: 0;
  }
}
.exchangeimg img {
  background: rgba(255, 255, 255, 0.1333333333);
}

.circlewrap {
  position: absolute;
  /* height: auto; */
  transition: all 0.3s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconswap {
  top: 4px;
  left: 5px;
  font-size: 41px !important;
  position: absolute;
  transform: rotateZ(270deg);
}

.animcirclemain {
  position: relative;
}

.circle {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.circle img {
  width: 100%;
  height: auto;
}

@keyframes righttoleft {
  100% {
    transform: rotate(-1turn);
  }
}
@media (max-width: 992px) {
  .container {
    display: block;
  }
  .topInformation {
    padding-right: 0px;
  }
}
@media (max-width: 576px) {
  .iconswap {
    transform: rotate(90deg);
  }
}
.text-reveal h2 {
  font-family: "Poppins", cursive !important;
  font-weight: 700;
}

.titleh2 {
  text-align: center;
}
.titleh2 h2 {
  text-align: center;
  margin: 0 0 15px;
  font-size: 3rem;
  text-transform: capitalize;
  padding: 0;
  margin-bottom: 15px;
}

.col4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
}

.cardbox {
  padding: 20px 15px;
  position: relative;
  text-align: center;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #fdc06c;
}
.cardbox h2 {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: 600;
}
.cardbox p {
  margin: 0px;
  font-size: 16px;
}

.os_row {
  display: flex;
  flex-wrap: wrap;
}

.boxsmain {
  margin-top: 50px;
}
.boxsmain .os_container {
  max-width: 800px;
}

.os_row {
  justify-content: center;
}

.os_container {
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  padding-left: 30px;
  padding-right: 30px;
}

.table-responsive {
  border: 1px solid #fdc06c;
}
.table-responsive .text-right {
  text-align: right;
}
.table-responsive thead {
  --cui-table-bg: #000000;
  --cui-table-color-state: #fff;
  --cui-border-width: 0px;
  --cui-border-color: #000;
  --cui-table-border-color: #000;
}
.table-responsive thead tr {
  background-color: #23163a;
}
.table-responsive thead tr th {
  padding: 15px;
}
.table-responsive tbody tr td {
  border-bottom: 1px solid #323232;
  padding: 15px 0px;
  margin: 0;
}
.table-responsive tr td {
  padding: 10px;
  text-align: center;
}
.table-responsive table {
  width: 100%;
}

.adminReportTable {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: auto;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionbtn {
  background: rgb(246, 178, 51) !important;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  color: #fff !important;
}

.link {
  align-items: center;
  background-image: url(../images/btnbgborder.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  letter-spacing: 0.8px;
  min-height: 48px;
  min-width: 167px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;
}
.link:hover {
  color: #f8aa45;
}

.balancewrap {
  border-radius: 6px;
  margin-bottom: 10px !important;
  margin-left: 8px !important;
}

.Mui-selected {
  background-color: rgb(246, 178, 51) !important;
  color: #000 !important;
}

@media only screen and (max-width: 992px) {
  .test {
    flex-basis: calc(50% - 20px) !important;
  }
  .logo {
    text-align: center !important;
  }
  .boxbtn {
    width: 180px !important;
    font-size: 12px !important;
  }
  .nftMain .popular_gaming_inner .nftsvideo {
    flex-direction: column;
  }
  .swapcontainer {
    padding-block: 35px;
    border: 1px solid #a67137;
    max-width: 520px;
    margin-top: 30px;
  }
  .lazyload-wrapper {
    width: 100%;
    height: auto;
  }
  .topInformation h1 {
    font-size: 35px;
    text-align: center;
  }
  .buttonGroup {
    justify-content: center;
  }
  .nftMain {
    padding: 40px;
  }
  .css-m9glnp-MuiPaper-root-MuiDialog-paper {
    width: 80% !important;
    height: 50% !important;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 150%;
  }
}
@media (max-width: 767px) {
  .test {
    flex-basis: 100% !important;
  }
  .text-reveal h2 {
    font-size: 2rem;
  }
  .boxbtn {
    width: 180px !important;
    font-size: 12px !important;
  }
  .btnMU,
  .btn-btnMU {
    font-size: 14px;
  }
  .informationMU .infotxt {
    text-align: center;
  }
  .informationMU .infotxt p {
    font-size: 13px;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 70%;
  }
  .nftMain {
    padding: 0;
  }
  .nftMain .stakebtn {
    padding: 4px 7px;
    border-radius: 9px;
  }
  .countDownMu p {
    font-size: 14px;
  }
  .footer_bottom_inner {
    flex-direction: column;
    text-align: center;
  }
  .copyright_right {
    margin-bottom: 12px;
  }
}
@media (max-width: 575px) {
  .box {
    flex-direction: column;
    align-items: center;
  }
  .box.swapChange {
    flex-direction: column-reverse;
  }
  .test {
    flex-basis: 100% !important;
  }
  .wnbtn {
    display: none;
  }
  .mobilebtn {
    background-color: #bc4242;
    padding: 10px;
    border-Radius: 20px;
    cursor: pointer;
    margin-right: 4px;
    display: block !important;
    margin: 0 auto;
  }
  .informationMU .fqbox {
    padding: 0px;
  }
  .logo b {
    font-size: 26px !important;
  }
  .popular_gaming_inner .swiper-button-next,
  .popular_gaming_inner .swiper-button-prev {
    height: 35px;
    width: 35px;
  }
  .nftMain .nftheadline {
    font-size: 35px;
  }
  .topInformation h1 {
    font-size: 35px;
  }
  .news h3 {
    font-size: 35px;
  }
  .nftBalance h3 {
    font-size: 35px;
  }
  .informationMU .infotxt h3 {
    font-size: 35px;
  }
  .topInformation .btnMU,
  .topInformation .btn-btnMU {
    font-size: 16px;
  }
  .listViewBx.ms\:mt-5.pt-5 {
    padding-top: 0 !important;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 44vw;
  }
  .nftBalance .balanceViewBx ul li {
    margin-bottom: 15px;
    font-size: 14px;
  }
  main section {
    margin-bottom: 30px;
  }
  .connectWalletToggle .popupbtngrp button,
  .connectWalletToggle .popupbtngrp a {
    font-size: 14px;
  }
  .footer_bottom_link_menu ul li {
    margin-right: 0;
    padding-right: 8px;
  }
  .footer_social ul li a {
    width: 22px;
    height: 22px;
  }
  footer .copyright span {
    display: block;
    margin-top: 4px;
  }
  footer .footer {
    padding: 35px 0px 15px;
  }
  .connectWalletToggle .modal-content .modal-body {
    padding: 0px;
  }
  .connectWalletToggle .modal-content .modal-header {
    padding: 20px 0px;
  }
  header .header-brand img {
    max-width: 155px;
  }
  .topInformation h1 .lstwld {
    display: block;
  }
  .topInformation {
    margin-bottom: 20px;
  }
  .countDownMu {
    font-size: 35px;
  }
  .countDownMu p {
    font-size: 13px;
  }
  .countDownMu p span {
    display: block;
  }
  footer .social-icons a {
    width: 40px;
    height: 40px;
    margin: 0px 5px !important;
  }
}
@media only screen and (max-width: 390px) {
  .nftBalance .balanceViewBx ul li {
    font-size: 12px;
  }
  .topInformation .btnMU,
  .topInformation .btn-btnMU {
    min-width: 105px;
  }
  .header .btnMU,
  .header .btn-btnMU {
    font-size: 15px;
  }
  .header .btnMU svg,
  .header .btn-btnMU svg {
    font-size: 1rem;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 60vw;
  }
  .connectWalletToggle .popupbtngrp button img,
  .connectWalletToggle .popupbtngrp a img {
    height: 40px;
    width: 40px;
  }
  .connectWalletToggle .popupbtngrp button,
  .connectWalletToggle .popupbtngrp a {
    font-size: 12px;
    padding: 10px 4px 10px;
  }
  .connectWalletToggle .modal-content .modal-header {
    padding: 15px 10px;
  }
  .connectWalletToggle .modal-content .modal-title {
    font-size: 18px;
  }
  header .header-brand img {
    max-width: 125px;
  }
  .header .btnMU span,
  .header .btn-btnMU span {
    display: block;
    padding: 0px 6px;
    font-size: 10px;
  }
  main {
    padding: 15px 0px;
  }
}
@media only screen and (min-width: 767px) {
  .popular_gaming_inner > .swiper .swiper-slide.swiper-slide-next {
    transform: scale(1.2);
    position: relative;
    z-index: 9;
  }
  .popular_gaming_inner > .swiper {
    transform: scale(0.95);
    padding-top: 60px;
    padding-bottom: 90px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.bannerimg img {
  margin: 0 auto;
  /* max-width: 400px; */
  width: 100%;
  height: auto;
}

.animcircle {
  position: relative;
  animation: mykdRotation 12s linear infinite reverse;
  animation-play-state: running;
}
.animcircle img {
  pointer-events: none;
}
.animcircle svg {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-33deg);
  overflow: initial;
}
.animcircle svg text {
  fill: #FFFFFF;
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 6.3px;
}
@keyframes mykdRotation {
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=main.css.map */